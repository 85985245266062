export function isDataEmpty(data) {
  if (data === null || data === undefined) {
    return true
  }

  if (typeof data === 'string') {
    return data === ''
  }

  if (typeof data === 'object') {
    return Object.keys(data).length === 0
  }

  if (Array.isArray(data)) {
    return data.length === 0
  }

  if (typeof data === 'number') {
    return data === 0
  }

  return false
}